import React, { useEffect, useState, Fragment } from 'react'
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';


export default function DetailsPage() {

    const [data, setdata] = useState([])
    const pathname = window.location.pathname
    const [currentUrl, setCurrentUrl] = React.useState(pathname)
    let [CategoryAtricle, SetCategoryAtricle] = useState("");

    useEffect(() => {

        setCurrentUrl(pathname)
        var url = window.location.href.replace("https://www.reviewtimes.in/", '');
        url = url.replace("https://reviewtimes.in/", '');
        const categ = url.split('/');
        SetCategoryAtricle(categ[0]);

        url = url.replace("/", "$").replace("/", "$").replace("/", "$");
        fetch('https://www.reviewtimes.in/WeatherForecast/Getbycode/' + url).then((response) => response.json()).then((result) => {
            console.log(result)

            document.title = result.pageTitle
            //("META")[3] means 3rd meta tag on page
            document.getElementsByTagName("META")[3].content = result.metaDecription;
            document.getElementsByTagName("META")[4].content = result.pageTitle;

            setdata(result)
        })

    }, [pathname])


    return (

        <>

            <div class="breadcrumb-wrap">
                <div class="container">
                    <h2 class="breadcrumb-title">{CategoryAtricle} Article Details</h2>


                    <ul class="breadcrumb-menu list-style">
                        <li><NavLink tag={Link} to="/">Home</NavLink></li>
                        <li><NavLink tag={Link} to={"/" + CategoryAtricle}>  {CategoryAtricle} </NavLink></li>
                        <li>Article Details</li>


                    </ul>
                </div>
            </div>


            <div class="news-details-wrap ptb-100">
                <div class="container">
                    <div class="row gx-55 gx-5">


                        <div class="col-lg-8">
                            
                            {

                                
                                <Fragment>
                                    <article>
                                        <div class="news-img">
                                            <img src={data.detailPageImg} alt={data.detailPageImgAltText} />
                                            <a class="news-cat">{data.category}</a>
                                        </div>

                                        <ul class="news-metainfo list-style">
                                            <li class="author">
                                                <span class="author-img">
                                                    <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                                </span>
                                                 &nbsp; Application owner
                                            </li>
                                            <li><i class="fi fi-rr-calendar-minus"></i>{new Date(data.newsCreateDate).toDateString()}</li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>

                                        <div class="news-para">
                                            <h1>{data.newsHeading}</h1>
                                            <div dangerouslySetInnerHTML={{ __html:data.newsText}}></div>
                                        </div>
                                    </article>
                                </Fragment>
                                

                            }


                            <div class="post-pagination">
                                <a class="prev-post" >
                                    <span>PREVIOUS</span>
                                    <h6>The Future Of Business: Predictions And Trends To Watch</h6>
                                </a>
                                <a class="next-post" >
                                    <span>NEXT</span>
                                    <h6>From Start-up To Scale-up: Navigating Growth In Your Business</h6>
                                </a>
                            </div>
                            <h3 class="comment-box-title">3 Comments</h3>
                            <div class="comment-item-wrap">
                                <div class="comment-item">
                                    <div class="comment-author-img">
                                        <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                    </div>
                                    <div class="comment-author-wrap">
                                        <div class="comment-author-info">
                                            <div class="row align-items-start">
                                                <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                    <div class="comment-author-name">
                                                        <h5>Killian Mider</h5>
                                                        <span class="comment-date">Jul 22, 2023 | 7:10 PM</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                    <a href="#cmt-form" class="reply-btn">Reply</a>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                    <div class="comment-text">
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                                            magna aliquyam.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-item reply">
                                    <div class="comment-author-img">
                                        <img src="assets/img/author/author-thumb-2.webp" alt="Image" />
                                    </div>
                                    <div class="comment-author-wrap">
                                        <div class="comment-author-info">
                                            <div class="row align-items-start">
                                                <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                    <div class="comment-author-name">
                                                        <h5>Everly Leah </h5>
                                                        <span class="comment-date">Jul 23, 2023 | 7:10 PM</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                    <a href="#cmt-form" class="reply-btn">Reply</a>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                    <div class="comment-text">
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                                            magna aliquyam erat.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-item">
                                    <div class="comment-author-img">
                                        <img src="assets/img/author/author-thumb-3.webp" alt="Image" />
                                    </div>
                                    <div class="comment-author-wrap">
                                        <div class="comment-author-info">
                                            <div class="row align-items-start">
                                                <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                    <div class="comment-author-name">
                                                        <h5>Michel Ohio</h5>
                                                        <span class="comment-date">Jun 14, 2023 | 7:10 PM</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                    <a href="#cmt-form" class="reply-btn">Reply</a>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                    <div class="comment-text">
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                                            magna aliquyam.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="cmt-form">
                                <div class="mb-30">
                                    <h3 class="comment-box-title">Leave A Comment</h3>
                                    <p>Your email address will not be published. Required fields are marked.</p>
                                </div>
                                <form action="#" class="comment-form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" required placeholder="Name*" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" required placeholder="Email Address*" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <textarea name="messages" id="messages" cols="30" rows="10" placeholder="Please Enter Your Comment Here"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-check checkbox">
                                                <input class="form-check-input" type="checkbox" id="test_2" />
                                                <label class="form-check-label" for="test_2">
                                                    Save my info for the next time I commnet.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button class="btn-two">Post A Comment<i class="flaticon-right-arrow"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>



                        <div class="col-lg-4">
                            <div class="sidebar">
                                <div class="sidebar-widget-two">
                                    <form action="#" class="search-box-widget">
                                        <input type="search" placeholder="Search" />
                                        <button type="submit">
                                            <i class="fi fi-rr-search"></i>
                                        </button>
                                    </form>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Categories</h3>
                                    <ul class="category-widget list-style">
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Celebration <span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Culture<span>(3)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Fashion<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Inspiration<span>(8)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Lifestyle<span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Politics<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Trending<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Recent Posts</h3>
                                    <div class="pp-post-wrap">
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-4.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Bernie Nonummy Pelopai Iatis Eum Litora</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-5.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 23, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-6.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 14, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-7.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">First Prototype Flight Using Kinetic Launch System</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-8.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Beauty Queens Need Material & Products</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 03, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-9.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">That Woman Comes From Heaven Look Like Angel</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 01, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Popular Tags</h3>
                                    <ul class="tag-list list-style">
                                        <li><a href="#">BUSINESS</a></li>
                                        <li><a href="#">FOOD</a></li>
                                        <li><a href="#">SCIENCE</a></li>
                                        <li><a href="#">LIFESTYLE</a></li>
                                        <li><a href="#">SPORTS</a></li>
                                        <li><a href="#">PHOTO</a></li>
                                        <li><a href="#">TECHNOLOGY</a></li>
                                        <li><a href="#">CONTENT</a></li>
                                        <li><a href="#">FEATURED</a></li>
                                        <li><a href="#">AUDIO</a></li>
                                        <li><a href="#">FASHION</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}