import React, { useEffect, useState } from 'react'
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Category() {

    const [data, setdata] = useState([])
    const pathname = window.location.pathname
    const [currentUrl, setCurrentUrl] = React.useState(pathname)

    let [CategoryAtricle, SetCategoryAtricle] = useState("");

    useEffect(() => {
        setCurrentUrl(pathname)
        var url = window.location.href.replace("https://www.reviewtimes.in/", '');
        url = url.replace("https://reviewtimes.in/", '');
        
        SetCategoryAtricle(url);
        url = url.replace("/", "$");
        try {

            fetch('https://www.reviewtimes.in/Getbycotegory/' + url).then((response) => response.json()).then((result) => {
                console.log(result)
                document.title = result[0].pageTitle
                //("META")[3] means 3rd meta tag on page
                document.getElementsByTagName("META")[3].content = result[0].metaDecription;
                document.getElementsByTagName("META")[4].content = result[0].pageTitle;
                setdata(result);
            })
        }
        catch (e) {
            console.log(e)
        }
        

    }, [pathname])


    return (

        <>
            <div class="breadcrumb-wrap">
                <div class="container">
                    <h2 class="breadcrumb-title">{ CategoryAtricle } Article</h2>
                    <ul class="breadcrumb-menu list-style">
                        <li><NavLink tag={Link} to="/">Home</NavLink></li>
                        <li>Business Article</li>
                    </ul>
                </div>
            </div>
            <div class="sports-wrap ptb-100">
                <div class="container">
                    <div class="row gx-55 gx-5">
                        <div class="col-lg-8">
                            <div class="row justify-content-center">

                                {
                                    
                                    data.map((item) =>

                                                <div class="col-md-6">
                                                    <div class="news-card-thirteen">
                                                        <div class="news-card-img">
                                                            <img src={item.homePageImg} alt="Iamge" />
                                                            <a class="news-cat">Business</a>
                                                        </div>
                                                        <div class="news-card-info">
                                                            <h3><NavLink tag={Link} to={item.newsUrl}> {item.newsHeading} </NavLink></h3>
                                                            <ul class="news-metainfo list-style">
                                                                <li><i class="fi fi-rr-calendar-minus"></i>{new Date(item.newsCreateDate).toDateString()}</li>
                                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        )
                                        
                                }
                                
                            </div>
                            
                        </div>
                        <div class="col-lg-4">
                            <div class="sidebar">
                                <div class="sidebar-widget-two">
                                    <form action="#" class="search-box-widget">
                                        <input type="search" placeholder="Search" />
                                        <button type="submit">
                                            <i class="fi fi-rr-search"></i>
                                        </button>
                                    </form>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Categories</h3>
                                    <ul class="category-widget list-style">
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Celebration <span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Culture<span>(3)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Fashion<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Inspiration<span>(8)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Lifestyle<span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Politics<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Trending<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Recent Posts</h3>
                                    <div class="pp-post-wrap">
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-4.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Bernie Nonummy Pelopai Iatis Eum Litora</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-5.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 23, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-6.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 14, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-7.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">First Prototype Flight Using Kinetic Launch System</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Popular Tags</h3>
                                    <ul class="tag-list list-style">
                                        <li><a href="#">BUSINESS</a></li>
                                        <li><a href="#">FOOD</a></li>
                                        <li><a href="#">SCIENCE</a></li>
                                        <li><a href="#">LIFESTYLE</a></li>
                                        <li><a href="#">SPORTS</a></li>
                                        <li><a href="#">PHOTO</a></li>
                                        <li><a href="#">TECHNOLOGY</a></li>
                                        <li><a href="#">CONTENT</a></li>
                                        <li><a href="#">FEATURED</a></li>
                                        <li><a href="#">AUDIO</a></li>
                                        <li><a href="#">FASHION</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}